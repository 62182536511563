import React from 'react';
import SimpleLocalize from "../components/SimpleLocalize/SimpleLocalize";
import MainLayout from "../components/Layouts/MainLayout/MainLayout";
import TeamSection from "../components/Sections/TeamSection/TeamSection";

const TeamPage = (props) => {
    return (
        <>
            <SimpleLocalize {...props}>
                <MainLayout
                    currentLang={props.pageContext.language}
                    title={props.pageContext.messages['team']}
                    description={props.pageContext.messages['team_desc']}
                    locationProps={props.location}
                    navigate={props.navigate}
                >
                    <TeamSection />
                </MainLayout>
            </SimpleLocalize>
        </>
    );
}

export default TeamPage;
