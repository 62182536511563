import React from "react";
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from "gatsby-plugin-image"



function TeamCard({teamSection, member, img}) {
    const image = getImage(img);
    return (
        <div className={`team-card ${teamSection}-team`}>
            <GatsbyImage
                className={`team-img ${teamSection}-team-member ${member.image}`}
                image={image}
                alt={member.image}
            />
            <h5>{member.name}</h5>
            <h6>{member.title}</h6>
            <div className="links">
                {member.additionalLinks.map(link => {
                    return (
                        <a href={link.href} key={link.href} target="_blank" rel="noreferrer noopener">
                            <img
                                src={`/${link.image}.svg`}
                                className="team-social-link"
                                alt={`${member.name} - ${link.name}`}
                            />
                        </a>
                    )
                })}
            </div>
        </div>
    );
}

// export const pageQuery = useStaticQuery(graphql`
//     query {
//         file (
//            relativePath: { eq: "team/stefan-miric.jpg" }
//         ) {
//
//       }
//     }
// `);

TeamCard.propTypes = {
    member: PropTypes.object.isRequired,
    teamSection: PropTypes.string.isRequired,
}


export default TeamCard;